<template>
  <ion-page>
    <ion-content :scrollY="false">
      <div class="login-wrapper">
        <IonImg src="assets/logo-print.png" alt="logo kkp" class="logo"/>
        <div class="form-area ion-padding ion-margin-top" :class="{ 'show': showForm }">
          <h1 class="ion-text-center">
            SIMANTAP
          </h1>
          <h2>Login</h2>
          <p>Silakan login untuk memulai sesi.</p>
          <form @submit.prevent="handleSubmit">
            <ion-item class="ion-margin-bottom">
              <ion-icon :icon="mail" slot="start"/>
              <ion-input type="text" v-model="loginInfo.email" placeholder="Email"/>
            </ion-item>
            <ion-item class="ion-margin-bottom">
              <ion-icon :icon="lockClosed" slot="start"/>
              <ion-input :type="passwordType" v-model="loginInfo.password" placeholder="Password"/>
              <ion-icon :icon="eye" slot="end" class="toggle-password md hydrated"
                        :class="{ 'active md hydrated': passwordType === 'text' }"
                        @click="handleEye"></ion-icon>
            </ion-item>
            <ion-button expand="block" class="submit-button ion-margin-bottom" type="submit">
              Login
            </ion-button>
            <div class="ion-text-center">
              <router-link to="/">
                Lupa password?
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </ion-content>
    <ion-footer class="ion-text-center">
      <ion-toolbar>
        Belum punya akun?
        <router-link to="/">
          Registrasi
        </router-link>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<style lang="scss" scoped>
.submit-button {
  margin-top: 32px;
}

.toggle-password {
  margin-top: 15px;

  &.active {
    color: var(--ion-color-primary);
  }
}

.logo {
  &::part(image) {
    max-height: 25vh;
  }

  margin-left: auto;
  margin-right: auto;
}

ion-content {
  --background: linear-gradient(135deg, #CE9FFC 10%, var(--ion-color-primary) 50%);
}

.login-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form-area {
  height: 100%;

  background-color: #fff;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  //position: fixed;
  //bottom: 0;
  //left: 0;
  //height: 65vh;
  //padding-bottom: 71px;
  width: 100%;
  transition: transform 200ms ease-out;
  transform: translateY(100%);
  overflow-y: auto;

  &.show {
    transform: translateY(0);
  }
}
</style>

<script>
import {defineComponent, onMounted, reactive, ref} from 'vue';
import {
  IonButton,
  IonContent,
  IonFooter,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonPage,
  IonToolbar,
  loadingController
} from '@ionic/vue';
import {eye, lockClosed, mail} from 'ionicons/icons';
import {AuthService} from '../services/auth';
import {useRouter} from 'vue-router';

export default defineComponent({
  components: {
    IonContent,
    IonImg,
    IonIcon,
    IonInput,
    IonFooter,
    IonToolbar,
    IonPage,
    IonItem,
    IonButton
  },
  setup() {
    const loginInfo = reactive({
      email: 'external2@mail.com',
      password: 'external2'
    });
    const passwordType = ref('password');
    const showForm = ref(false);
    const router = useRouter();

    const handleEye = () => {
      if (passwordType.value === 'password') {
        passwordType.value = 'text';
      } else {
        passwordType.value = 'password';
      }
    };

    const handleSubmit = async () => {
      const loading = await loadingController.create({
        message: 'Loggin in...'
      });
      await loading.present();
      await AuthService.login(loginInfo);
      await loading.dismiss();

      if (router.currentRoute.value.query.redirect) {
        return router.replace(router.currentRoute.value.query.redirect);
      }
      return router.push('/');
    };

    onMounted(() => {
      setTimeout(() => {
        showForm.value = true;
      }, 1000);
    });

    return {
      mail,
      lockClosed,
      eye,
      loginInfo,
      passwordType,
      handleEye,
      showForm,
      handleSubmit
    };
  }
});
</script>
